import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import ReactMarkdown from "react-markdown"
import { Row, Col } from "react-bootstrap"
import Testimonials from "../components/testimonials"

const Admission = ({ data: { strapiIcdaCareers } }) => {
  return (
    <Layout>
      <SEO title="Admisiones - ICDA" />
      <section>
        <div className="container py-5">
          <p className="mb-4 fw-bold text-uppercase">ADMISIONES</p>
          <h1 className="mb-4 fw-bold text-uppercase">
            ADMISIÓN DE {strapiIcdaCareers.name}
          </h1>
          {strapiIcdaCareers.admission.requirements ? (
            <div className="program mb-5 p-4 bg-sec text-white">
              <Row className="mb-3">
                <Col>
                  <h2 className="font-weight-bold">Requisitos</h2>
                  <h5 className="font-weight-bold">{strapiIcdaCareers.admission.requirements.title}</h5>
                    <p>
                      <ReactMarkdown
                        className="format-markdown"
                        source={strapiIcdaCareers.admission.requirements.summary}
                      />
                    </p>
                </Col>
              </Row>
              {strapiIcdaCareers.admission.requirements.List.map(req => (
                <Row>
                  <Col>
                    <h5 className="font-weight-bold">{req.title}</h5>
                    <p>
                      <ReactMarkdown
                        className="format-markdown"
                        source={req.summary}
                      />
                    </p>
                  </Col>
                </Row>
              ))}
            </div>
          ) : null}
          {strapiIcdaCareers.admission.process ? (
            <div className="process mb-5">
              <h2 className="font-weight-bold mb-4">Proceso de admisión</h2>
              <Row>
                {strapiIcdaCareers.admission.process.Accordion.map(
                  (proc, i) => (
                    <Col xs={12}>
                      <div className="border-left ml-3 d-flex">
                        <div className="bg-sec rounded-circle ml-n3 circle text-white">
                          <p className="mb-0">{i + 1}</p>
                        </div>
                        <div className="pl-3 pb-3 flex-grow-1">
                          <h5 className="font-weight-bold pb-2">
                            {proc.title}
                          </h5>
                          <p>
                            <ReactMarkdown
                              className="format-markdown"
                              source={proc.summary}
                            />
                          </p>
                        </div>
                      </div>
                    </Col>
                  )
                )}
              </Row>
            </div>
          ) : null}
          {strapiIcdaCareers.inscriptionLink ? (
            <div className="text-center mb-4 d-flex">
              <div className="pb-3 flex-grow-1">
                <h2 className="font-weight-bold pb-2">¿Aceptás el desafío?</h2>
                <Link
                  to={strapiIcdaCareers.inscriptionLink}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-primary btn-lg"
                >
                  Inscribirme
                </Link>
              </div>
            </div>
          ) : null}
          {strapiIcdaCareers.testimonials &&
          strapiIcdaCareers.testimonials.length > 0 ? (
            <div className="container">
              <Testimonials testimonials={strapiIcdaCareers.testimonials} />
            </div>
          ) : null}
        </div>
      </section>
    </Layout>
  )
}

export default Admission

export const admissionQuery = graphql`
  query($id: String) {
    strapiIcdaCareers(id: { eq: $id }) {
      name
      id
      slug
      inscriptionLink
      career_category {
        name
      }
      admission {
        id
        name
        requirements {
          id
          title
          summary
          List {
            summary
            title
            id
          }
        }
        process {
          id
          summary
          Accordion {
            id
            summary
            title
          }
        }
      }
      testimonials {
        name
        testimonial {
          company
          content
          position
          visible
        }
      }
    }
  }
`
